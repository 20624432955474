import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import Ctasection from '../../containers/Hosting/Ctasection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import BannerSectionApplication from '../../containers/Hosting/Banner/BannerApplication';
import InfoApplicationSection from '../../containers/Hosting/InfoApplication/Buildyourown';
import TopRibbon from '../../containers/Hosting/TopRibbon';

class BuildYourOwnPage extends Component {
  render() {
    const name = 'BuildYourOwn';
    return (
      <ThemeProvider theme={hostingTheme}>
        <ParallaxProvider>
          <SEO
            title="Automate tasks with RAPFlow & RAPBot"
            description="Leverage the power of RAPFlow and RAPBot for automation, virtually across all the processes in your organization. Build your own AI solution here."
          />

          <ResetCSS />
          <GlobalStyle />
          <TopRibbon />
          <ContentWrapper className="BuildYourOwnPage">
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSectionApplication name={name} />
            <InfoApplicationSection name={name} />
            <Ctasection name={name} />
            <Footer />
          </ContentWrapper>
        </ParallaxProvider>
      </ThemeProvider>
    );
  }
}

export default BuildYourOwnPage;
