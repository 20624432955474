import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
// import Button from 'common/src/components/Button';
// import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { useStaticQuery, graphql } from 'gatsby';

import BgImageDark from '../../BgImagedark';

// import sample from 'common/src/assets/image/hosting/author-3.jpg';

// import BgImageLight from '../../BgImageLight';

import '../infoapplication.css';
import './infobuildyourown.css';

const InfoApplicationSection = ({
  sectionWrapper,
  row,
  col,
  col20,
  col30,
  col70,
  iconHead,
  iconsubHead,
  iconDescription,
  description,
  title,
  contentArea,
  textArea,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          BUILD_YOUR_OWN_Benefit {
            Category
            Items {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          BUILD_YOUR_OWN_Capability {
            Category
            Items {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Box {...sectionWrapper}>
      <Box className="section_background_color">
        <Container>
          <Box {...contentArea}>
            <Fade bottom cascade>
              {/* <Heading {...title} className="text_center" content="Overview" /> */}
              <div className="empty_space40" />
              <Text
                {...description}
                className="text_center"
                dangerouslySetInnerHTML={{
                  __html:
                    "Leverage the power of <a href='/platform/rapflow'>RAPFlow</a> and <a href='/platform/rapbot'>RAPBot</a> for meaningful automation virtually across all the processes in your organization. Aside from the existing suite of applications, you can build your very own AI solution. The first step is identifying tasks and structured workflows that are good candidates for automation. You can transform your business applications through RAP AI for a more streamlined approach that boosts operational efficiency.",
                }}
              />
            </Fade>
            <div className="empty_space40" />
            {/* <div className="empty_space40" /> */}
          </Box>
        </Container>
      </Box>
      {Data.hostingJson.BUILD_YOUR_OWN_Benefit.map((widget, index) => (
        <Box
          {...sectionWrapper}
          key={`benefits-${index}`}
          className={`section_background_blue ${widget.Category}`}
        >
          <BgImageDark />
          <Container>
            <Fade bottom cascade>
              <Heading
                {...title}
                className="text_center"
                content="Automation Criteria"
              />
              <Text
                {...description}
                className="text_center text_white"
                content="Certain tasks and types of workflows are good candidates for AI-powered automation. Criteria to consider are as follows:"
              />
            </Fade>
            <div className="empty_space30" />
            <Fade bottom cascade delay={50}>
              <Box {...row} className="margin_auto flexjustifyaround">
                {widget.Items.map((item, index) => (
                  <Box
                    {...col}
                    key={`benefits-${index}`}
                    className="icontext_card"
                  >
                    <Box>
                      <Image
                        src={item.avatar.childImageSharp.fluid.src}
                        alt={`icon-${index}`}
                        className="icon_image"
                      />
                      <Text {...iconDescription} content={item.description} />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Fade>
          </Container>
        </Box>
      ))}
      {Data.hostingJson.BUILD_YOUR_OWN_Capability.map((widget, index) => (
        <Box
          {...sectionWrapper}
          key={`benefits-${index}`}
          className={`${widget.Category}`}
        >
          <Container>
            <div className="empty_space40" />
            <Fade bottom cascade>
              <Heading {...title} className="text_center" content="Benefits" />
            </Fade>
            <Fade bottom cascade delay={50}>
              <Box {...row} className="margin_auto flexjustifycenter">
                {widget.Items.map((item, index) => (
                  <Box
                    {...col}
                    key={`benefits-${index}`}
                    className="icontext_card icontext_card_whitebg"
                  >
                    <Box>
                      <Image
                        src={item.avatar.childImageSharp.fluid.src}
                        alt={`icon-${index}`}
                        className="icon_image"
                      />
                      <Heading {...iconHead} content={item.title} />
                      <Text {...iconDescription} content={item.description} />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Fade>
          </Container>
          <div className="empty_space40" />
        </Box>
      ))}
    </Box>
  );
};

InfoApplicationSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col1: PropTypes.object,
  col: PropTypes.object,
  iconHead: PropTypes.object,
  iconsubHead: PropTypes.object,
  iconDescription: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

InfoApplicationSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    id: 'IconFloating_section',
    className: 'IconFloating_section',
    pt: 0,
    background: 'transparent',
    // pb: ['60px', '80px', '80px', '100px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 0.45, 0.31, 0.31],
    pr: '15px',
    pl: '15px',
    mb: '20px',
  },
  col20: {
    className: 'col20',
    width: [0.4, 0.15, 0.15, 0.2],
  },
  col30: {
    className: 'col30',
    width: [1, 1, 1, 0.3],
  },
  col70: {
    className: 'col70',
    width: [1, 1, 1, 0.7],
  },
  iconHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconHead',
    textAlign: 'center',
  },
  iconsubHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconsubHead',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  iconDescription: {
    className: 'iconDescription',
    textAlign: 'center',
    fontSize: ['18px', '18px', '20px', '20px'],
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '25px',
    pt: '25px',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mb: '15px',
  },
};

export default InfoApplicationSection;
